import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { ScoreState } from "./ScoreProvider.js";

import useScores from "./useScores.js";

import gamesData from "./GamesData.json";

export default function Admin() {
  const teamOneName = localStorage.getItem("teamOneName");
  const teamTwoName = localStorage.getItem("teamTwoName");

  const [teamOneScore, teamTwoScore, teamOneItems, teamTwoItems] = useScores();

  const [tabOn, setTabOn] = useState(0);

  const scoresToUse = tabOn === 0 ? teamOneItems : teamTwoItems;

  let history = useHistory();

  const [scores, scoreDispatch] = useContext(ScoreState);

  const deleteScoreItem = (index) => {
    scoreDispatch({
      type: "deleteItem",
      team: tabOn === 0 ? "One" : "Two",
      index: index,
    });
  };

  const resetEverything = () => {
    if (window.confirm("Are you sure you want to reset everything?")) {
      for (var i = 0; i < localStorage.length; i++) {
        localStorage.removeItem(localStorage.key(i));
      }
      scoreDispatch({
        type: "reset",
      });
      history.push("/");
    }
  };

  return (
    <Wrapper>
      <TeamTabs>
        <TeamTab className={tabOn === 0 && "on"} onClick={() => setTabOn(0)}>
          {teamOneName}
        </TeamTab>
        <TeamTab className={tabOn === 1 && "on"} onClick={() => setTabOn(1)}>
          {teamTwoName}
        </TeamTab>
      </TeamTabs>

      <Scores>
        {scoresToUse.map((scoreItem, index) => {
          const { name } = gamesData.find(
            (data) => data.ID === scoreItem.gameID * 1
          );

          return (
            <ScoreItem key={tabOn + "_" + index}>
              {name} - {scoreItem.points}{" "}
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => deleteScoreItem(index)}
              />
            </ScoreItem>
          );
        })}
      </Scores>

      <Links>
        <Link to="/">
          <button>Home</button>
        </Link>
        <Link to="/teams">
          <button>Team Names</button>
        </Link>
        <Link to="/games">
          <button>Games</button>
        </Link>
        <Link to="/final">
          <button>Final</button>
        </Link>
        <button onClick={resetEverything}>Reset</button>
      </Links>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  background: #fff;
  z-index: 9000;
  width: 400px;
  top: 0;
  bottom: 0;
  color: #320f5a;
  box-shadow: 0 0 20px #320f5a;
  padding: 20px;
  overflow: auto;
`;
const TeamTabs = styled.div`
  display: flex;
  border-bottom: 2px solid #320f5a;
`;
const TeamTab = styled.div`
  padding: 10px;
  text-align: center;
  &.on {
    font-weight: bold;
    color: #fff;
    background: #320f5a;
  }
`;
const Links = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #320f5a;
  display: flex;
  justify-content: space-evenly;
`;

const Scores = styled.div``;

const ScoreItem = styled.div`
  border-bottom: 1px solid #828c8d;
`;
