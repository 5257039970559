import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import "./TitleScreen.css";

const variants = {
  initial: {
    x: 300,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -500,
    opacity: 0,
  },
};

const numberVariants = {
  initial: {
    y: 200,
    opacity: 0,
    scale: 3,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    x: -500,
    transition: {
      type: "tween",
    },
  },
};

export default function TransitionScreen({ onComplete, marginTop = 0 }) {
  const [leaving, setLeaving] = useState(false);

  const animations = {
    variants: {
      initial: {},
      animate: {
        transition: {
          staggerChildren: 0.11,
          delayChildren: 0.7,
        },
      },
      exit: {
        transition: {
          staggerChildren: 0.11,
          staggerDirection: -1,
        },
      },
    },
    initial: "initial",
    animate: leaving ? "exit" : "animate",
  };

  const numberAnimations = {
    variants: {
      initial: {},
      animate: {
        transition: {
          staggerChildren: 0.1,
          delayChildren: 1,
        },
      },
      exit: {
        transition: {
          staggerChildren: 0.1,
        },
      },
    },
    initial: "initial",
    animate: leaving ? "exit" : "animate",
  };

  const animationComplete = () => {
    if (leaving && onComplete) onComplete();
    else if (!leaving) setTimeout(() => setLeaving(true), 250);
  };

  return (
    <>
      <motion.div
        className="white_bg"
        initial={{ rotate: 25, y: -300, x: 800, width: 100 }}
        animate={{
          width: [100, 1500, 100],
          x: [800, -700, -1000],
        }}
      ></motion.div>
      <TitleContainer>
        <TextHolder>
          <motion.div {...animations} style={{ marginTop: marginTop }}>
            <motion.div variants={variants}>Adult</motion.div>
            <motion.div variants={variants}>Field</motion.div>
            <motion.div variants={variants}>Day</motion.div>
          </motion.div>
        </TextHolder>
      </TitleContainer>
      <NumberHolder>
        <motion.div
          {...numberAnimations}
          onAnimationComplete={animationComplete}
          style={{ marginTop: marginTop }}
        >
          <motion.div variants={numberVariants}>2</motion.div>
          <motion.div variants={numberVariants}>0</motion.div>
          <motion.div variants={numberVariants}>2</motion.div>
          <motion.div variants={numberVariants}>0</motion.div>
        </motion.div>
      </NumberHolder>
    </>
  );
}

const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 120px;
  color: #fff;
  text-shadow: 4px 4px 4px #421476;
  font-family: "Alfa Slab One", cursive;
  line-height: 1em;
`;

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 140px;
`;
const NumberHolder = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: absolute;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50%;
  text-shadow: 4px 4px 4px #421476;
  font-family: "Alfa Slab One", cursive;
  font-size: 320px;
  color: #fff;
  > div {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 480px;
  }
  > div > div {
    height: 220px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 4px 4px 8px #421476;
  }
`;

