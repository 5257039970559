import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTireRugged,
  faSurprise,
  faGlass,
  faBaseball,
  faBeer,
  faChevronLeft,
} from "@fortawesome/pro-duotone-svg-icons";
import { motion } from "framer-motion";

import { ScoreState } from "./ScoreProvider.js";

import gamesData from "./GamesData.json";

export default function TeamNames() {
  const teamOneName = localStorage.getItem("teamOneName");
  const teamTwoName = localStorage.getItem("teamTwoName");

  let history = useHistory();

  const [finalCount, setFinalCount] = useState(0);

  const [exiting, setExiting] = useState(false);

  const { gameID } = useParams();

  const { name, points, icon } = gamesData.find(
    (data) => data.ID === gameID * 1
  );

  const [scores, scoreDispatch] = useContext(ScoreState);

  const icons = {
    TireRugged: faTireRugged,
    Surprise: faSurprise,
    Glass: faGlass,
    Baseball: faBaseball,
    Beer: faBeer,
  };

  const addScore = (team) => {
    if (exiting) return;
    scoreDispatch({
      type: "addPoints",
      team: team,
      points: points,
      gameID: gameID,
    });

    setExiting(team);
  };

  const final = () => {
    if (finalCount > 1) history.push("/final");
    else setFinalCount(finalCount + 1);
  };

  const checkToGoBack = () => {
    if (exiting) setTimeout(() => history.push("/games"), 2000);
  };

  return (
    <>
      <GameEl>
        <CompleteButton onClick={final} />
        <GameTop>
          <motion.div
            initial={{ scale: 5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
          >
            <Link to="/games">
              <Icon>
                <FontAwesomeIcon icon={icons[icon]} />
              </Icon>
            </Link>
          </motion.div>
          <Title>
            <motion.div
              initial={{ y: -300 }}
              animate={{ y: 0, transition: { delay: 0.2 } }}
            >
              {name}
            </motion.div>
          </Title>
          <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0, transition: { delay: 0.4 } }}
          >
            <motion.div
              initial={{ rotate: 360 }}
              animate={{ rotate: 0, transition: { delay: 0.4 } }}
            >
              <Points>
                <PointsNumber>+{points}</PointsNumber>Points
              </Points>
            </motion.div>
          </motion.div>
        </GameTop>
        <Teams>
          <motion.div
            whileTap={{ scale: 0.8 }}
            initial={{ x: -1000 }}
            animate={{
              x: 0,
              y: exiting === "Two" ? 400 : 0,
              scale: exiting === "One" ? 1.5 : 1,
              transition: { delay: exiting ? 0.25 : 1 },
            }}
            onAnimationComplete={checkToGoBack}
          >
            <TeamName onClick={() => addScore("One")}>{teamOneName}</TeamName>
          </motion.div>
          <motion.div
            whileTap={{ scale: 0.8 }}
            initial={{ x: 1000 }}
            animate={{
              x: 0,
              y: exiting === "One" ? 400 : 0,
              scale: exiting === "Two" ? 1.5 : 1,
              transition: { delay: exiting ? 0.25 : 1 },
            }}
            onAnimationComplete={checkToGoBack}
          >
            <TeamName onClick={() => addScore("Two")}>{teamTwoName}</TeamName>
          </motion.div>
        </Teams>
      </GameEl>
    </>
  );
}

const CompleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background: white;
  opacity: 0;
`;
const Icon = styled.div`
  font-size: 180px;
  width: 220px;
  text-align: right;
`;
const GameEl = styled.div`
  color: #fff;
  padding: 80px;
  & a {
    color: #fff !important;
  }
`;

const Title = styled.div`
  font-size: 72px;
  font-family: "Alfa Slab One", cursive;
  text-shadow: 2px 2px 8px #320f5a;
  margin: 0 80px;
  text-align: center;
`;
const Points = styled.div`
  font-size: 52px;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 8px #320f5a;
  background: #fff;
  border-radius: 220px;
  color: #320f5a;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  font-weight: bold;
  flex-direction: column;
`;

const TeamName = styled.div`
  background: #fff;
  color: #320f5a;
  font-size: 52px;
  padding: 12px 20px;
  text-align: center;
  border-radius: 20px;
  margin: 20px;
  box-shadow: 2px 2px 8px #320f5a;
`;

const Teams = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GameTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const PointsNumber = styled.div`
  font-family: "Alfa Slab One", cursive;
  font-size: 96px;
  line-height: 60px;
  margin-top: 24px;
`;
