import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

export default function TeamNames() {
  const [teamOneName, setTeamOneName] = useState(
    localStorage.getItem("teamOneName") || ""
  );
  const [teamTwoName, setTeamTwoName] = useState(
    localStorage.getItem("teamTwoName") || ""
  );

  const setTeamName = (teamNumber, name) => {
    localStorage.setItem("team" + teamNumber + "Name", name);
    if (teamNumber === "One") setTeamOneName(name);
    else setTeamTwoName(name);
  };

  const [exiting, setExiting] = useState(false);

  let history = useHistory();

  const go = "Let's Do This!";

  const animations = {
    variants: {
      initial: {},
      animate: {
        transition: {
          staggerChildren: 0.07,
        },
      },
      exit: {
        transition: {
          staggerChildren: 0.07,
          staggerDirection: -1,
        },
      },
    },
    initial: "initial",
    animate: exiting ? "exit" : "animate",
  };

  const variants = {
    initial: {
      scale: 0.2,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
    },
    exit: {
      scale: 0.2,
      opacity: 0,
    },
  };

  const letsGo = () => {
    setExiting(true);
  };

  const goToGames = () => {
    if (exiting) history.push("/games");
  };

  return (
    <Wrapper>
      <motion.div {...animations} onAnimationComplete={goToGames}>
        <motion.div variants={variants}>
          <TeamInput
            placeholder="Team One"
            value={teamOneName}
            onChange={(e) => setTeamName("One", e.target.value)}
          />
        </motion.div>
        <motion.div variants={variants}>
          <Vs>VS</Vs>
        </motion.div>
        <motion.div variants={variants}>
          <TeamInput
            placeholder="Team Two"
            value={teamTwoName}
            onChange={(e) => setTeamName("Two", e.target.value)}
          />
        </motion.div>
        <motion.div variants={variants}>
          <GoButton onClick={letsGo}>{go}</GoButton>
        </motion.div>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  & div {
    text-align: center;
  }
`;

const TeamInput = styled.input`
  font-size: 48px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border: 4px solid #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 20px;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;
const Vs = styled.div`
  text-shadow: 4px 4px 4px #421476;
  font-family: "Alfa Slab One", cursive;
  color: #fff;
  font-size: 48px;
  padding: 30px;
`;

const GoButton = styled.button`
  font-family: "Alfa Slab One", cursive;
  background: #fff;
  font-size: 48px;
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  color: #421476;
  box-shadow: 2px 2px 4px #421476;
  margin-top: 30px;
`;
