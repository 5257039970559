import React, { createContext, useReducer } from "react";

const clearState = {
  teamOneScores: [],
  teamTwoScores: [],
};

const initialState = JSON.parse(localStorage.getItem("scores")) || clearState;

const saveStorage = (scores) => {
  localStorage.setItem("scores", JSON.stringify(scores));
};

function scoreReducer(state, action) {
  let newState = { ...state };
  switch (action.type) {
    case "reset":
      newState.teamOneScores = [];
      newState.teamTwoScores = [];
      saveStorage(newState);
      console.log(newState);
      return newState;
    case "addPoints":
      newState["team" + action.team + "Scores"].push({
        gameID: action.gameID,
        points: action.points,
      });
      saveStorage(newState);
      return newState;
    case "deleteItem":
      newState["team" + action.team + "Scores"].splice(action.index, 1);
      saveStorage(newState);
      return newState;
    default:
      return newState;
  }
}

export const ScoreState = createContext();

export default function ScoreStateProvider({ children }) {
  return (
    <ScoreState.Provider value={useReducer(scoreReducer, initialState)}>
      {children}
    </ScoreState.Provider>
  );
}
