import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Container from "./Container.js";
import ScoreStateProvider from "./ScoreProvider.js";

function App() {
  return (
    <Router>
      <ScoreStateProvider>
        <Container />
      </ScoreStateProvider>
    </Router>
  );
}

export default App;
