import React, { useContext, useState, useEffect } from "react";
import { ScoreState } from "./ScoreProvider.js";

export default function useScores() {
  const [teamOneScore, setTeamOneScore] = useState(0);
  const [teamTwoScore, setTeamTwoScore] = useState(0);

  const [scores] = useContext(ScoreState);

  useEffect(() => {
    let teamOneScore = 0;
    let teamTwoScore = 0;

    scores.teamOneScores.forEach(
      (scoreItem) => (teamOneScore += scoreItem.points)
    );
    scores.teamTwoScores.forEach(
      (scoreItem) => (teamTwoScore += scoreItem.points)
    );

    setTeamOneScore(teamOneScore);
    setTeamTwoScore(teamTwoScore);
  });

  return [
    teamOneScore,
    teamTwoScore,
    scores.teamOneScores,
    scores.teamTwoScores,
  ];
}
