import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Confetti from "react-confetti";
import TransitionScreen from "./TransitionScreen.js";

import useScores from "./useScores.js";

export default function MiniScore() {
  const [show, setShow] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [confettiRun, setConfettiRun] = useState(false);
  const [confettiRecycle, setConfettiRecycle] = useState(true);
  const [finalCount, setFinalCount] = useState(0);

  const teamOneName = localStorage.getItem("teamOneName");
  const teamTwoName = localStorage.getItem("teamTwoName");

  const [teamOneScore, teamTwoScore] = useScores();

  let winnerName = "";

  if (teamOneScore === teamTwoScore) winnerName = "Everyone";
  else winnerName = teamOneScore > teamTwoScore ? teamOneName : teamTwoName;

  useEffect(() => {
    if (confettiRun) setTimeout(() => setConfettiRecycle(false), 5000);
  }, [confettiRun]);

  const confettiComplete = () => {
    setExiting(true);
  };

  const introComplete = () => {
    setShow(true);
  };

  useEffect(() => {
    if (resetting)
      setTimeout(() => {
        setShow(false);
        setExiting(false);
        setResetting(false);
        setConfettiRun(false);
        setConfettiRecycle(true);
      }, 500);
  }, [resetting]);

  const bigAnimate = show
    ? {
        scale: exiting ? 5 : 1,
        opacity: exiting ? 0 : 1,
        transition: {
          delay: exiting ? 0 : 0.3,
          type: "tween",
        },
      }
    : {};

  const littleAnimate = show
    ? { scale: exiting ? 0 : 1, opacity: exiting ? 0 : 1 }
    : {};

  const winnerAnimationComplete = () => {
    if (exiting) setResetting(true);
    else setTimeout(() => setConfettiRun(true), 500);
  };

  if (resetting) return null;

  return (
    <>
      {!show && <TransitionScreen onComplete={introComplete} />}
      <Confetti
        numberOfPieces={1000}
        run={confettiRun}
        recycle={confettiRecycle}
        onConfettiComplete={confettiComplete}
      />
      <Wrapper>
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={littleAnimate}
        >
          <Text>The Winner Is</Text>
        </motion.div>
        <motion.div
          style={{ width: "100%" }}
          initial={{ scale: 4, opacity: 0 }}
          animate={bigAnimate}
          onAnimationComplete={winnerAnimationComplete}
        >
          <Winner>{winnerName}!</Winner>
        </motion.div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  font-family: "Alfa Slab One", cursive;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  font-size: 64px;
  text-shadow: 4px 4px 4px #421476;
`;

const Winner = styled.div`
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  font-size: 96px;
  color: #421476;
  padding: 40px;
  box-shadow: 0 0 40px #421476;
  margin-top: 40px;
`;



