import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTireRugged,
  faSurprise,
  faGlass,
  faBaseball,
  faBeer,
} from "@fortawesome/pro-duotone-svg-icons";

import { motion } from "framer-motion";

import gamesData from "./GamesData.json";
import TransitionScreen from "./TransitionScreen.js";

export default function AllGames() {
  const [show, setShow] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [goToGameID, setGoToGameID] = useState(0);
  let history = useHistory();
  const [finalCount, setFinalCount] = useState(0);

  const icons = {
    TireRugged: faTireRugged,
    Surprise: faSurprise,
    Glass: faGlass,
    Baseball: faBaseball,
    Beer: faBeer,
  };

  const animations = {
    variants: {
      initial: {},
      animate: {
        transition: {
          staggerChildren: 0.11,
        },
      },
    },
    initial: "initial",
  };

  if (exiting) animations.animate = "exit";
  else if (show) animations.animate = "animate";

  const introComplete = () => {
    setShow(true);
  };

  const goToGame = (gameID) => {
    //history.push("/game/" + gameID);
    setExiting(true);
    setGoToGameID(gameID);
  };

  const animationComplete = () => {
    if (exiting && goToGameID > 0) history.push("/game/" + goToGameID);
  };

  const final = () => {
    if (finalCount > 1) history.push("/final");
    else setFinalCount(finalCount + 1);
  };

  let count = -1;
  return (
    <>
      {!show && (
        <TransitionScreen onComplete={introComplete} marginTop="-100px" />
      )}
      <CompleteButton onClick={final} />
      <GamesWrapper>
        <motion.div {...animations} onAnimationComplete={animationComplete}>
          {gamesData.map((data, index) => {
            if (data.ID !== goToGameID) count++;
            return (
              <motion.div
                variants={{
                  initial: {
                    scale: 0.5,
                    opacity: 0,
                  },
                  animate: {
                    scale: 1,
                    opacity: 1,
                  },
                  exit: {
                    scale: 0.5,
                    opacity: 0,
                    transition: {
                      type: "tween",
                      delay: exiting
                        ? data.ID === goToGameID
                          ? 0.85
                          : count * 0.1
                        : 0,
                    },
                  },
                }}
                key={data.ID}
                onClick={() => goToGame(data.ID)}
              >
                <GameName>
                  <FontAwesomeIcon icon={icons[data.icon]} />
                  {data.name}
                </GameName>
              </motion.div>
            );
          })}
        </motion.div>
      </GamesWrapper>
    </>
  );
}

const GamesWrapper = styled.div`
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }
  height: calc(100% - 140px);
`;

const GameName = styled.div`
  background: #fff;
  color: #320f5a;
  font-size: 52px;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 20px;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  font-family: "Alfa Slab One", cursive;
  box-shadow: 2px 2px 8px #320f5a;
  display: flex;
  & svg {
    margin-right: 20px;
  }
`;
const CompleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background: white;
  opacity: 0;
`;
