import React, { useState } from "react";
import styled from "styled-components";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import bgImage from "./images/bg-texture.jpg";

import TitleScreen from "./TitleScreen.js";
import TeamNames from "./TeamNames.js";
import AllGames from "./AllGames.js";
import Game from "./Game.js";
import MiniScore from "./MiniScore.js";
import Final from "./Final.js";
import Admin from "./Admin.js";

export default function Container() {
  const teamOneName = localStorage.getItem("teamOneName");
  const teamTwoName = localStorage.getItem("teamTwoName");

  const isFinal = useRouteMatch("/final");
  const isTeams = useRouteMatch("/teams");
  const isHome = useRouteMatch("/")?.isExact;

  const [showAdmin, setShowAdmin] = useState(false);

  return (
    <ContainerEl>
      <AnimatePresence>
        <Switch>
          <Route path="/teams">
            <TeamNames />
          </Route>
          <Route path="/games">
            <AllGames />
          </Route>
          <Route path="/game/:gameID">
            <Game />
          </Route>
          <Route path="/final">
            <Final />
          </Route>
          <Route>
            <TitleScreen key="title" />
          </Route>
        </Switch>
      </AnimatePresence>
      {!isFinal && !isTeams && !isHome && teamOneName && teamTwoName && (
        <MiniScore />
      )}
      <AdminButton onClick={() => setShowAdmin((current) => !current)}>
        Admin
      </AdminButton>
      {showAdmin && <Admin />}
    </ContainerEl>
  );
}

const ContainerEl = styled.div`
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  margin: 0 auto;
  width: 1194px;
  height: 672px;
  overflow: hidden;
  color: #fff;
`;

const AdminButton = styled.button`
  position: absolute;
  z=index: 9999;
  bottom: 0;
  left: 0;
  opacity: 0;
`;
