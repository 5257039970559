import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import useScores from "./useScores.js";

export default function MiniScore() {
  const teamOneName = localStorage.getItem("teamOneName");
  const teamTwoName = localStorage.getItem("teamTwoName");

  const [teamOneScore, teamTwoScore] = useScores();

  const animations = {
    initial: {
      scale: 6,
      color: "rgb(255,255,255)",
      y: -100,
    },
    animate: {
      scale: 1,
      y: 0,
      color: "rgb(66, 20, 118)",
      opacity: 1,
    },
    exit: {
      y: 200,
      opacity: 0,
    },
  };

  return (
    <Wrapper>
      <TeamScore>
        <TeamName>{teamOneName}</TeamName>
        <Points>
          <div style={{ opacity: 0 }}>{teamOneScore}</div>
          <AnimatePresence>
            <motion.div
              key={teamOneScore}
              {...animations}
              style={{ position: "absolute" }}
            >
              {teamOneScore}
            </motion.div>
          </AnimatePresence>
        </Points>
      </TeamScore>
      <TeamScore>
        <TeamName>{teamTwoName}</TeamName>
        <Points>
          <div style={{ opacity: 0 }}>{teamTwoScore}</div>
          <AnimatePresence>
            <motion.div
              key={teamTwoScore}
              {...animations}
              style={{ position: "absolute" }}
            >
              {teamTwoScore}
            </motion.div>
          </AnimatePresence>
        </Points>
      </TeamScore>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 4px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  justify-content: space-evenly;
`;

const TeamScore = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 50%;
`;

const TeamName = styled.div`
  font-size: 32px;
  font-weight: bold;
  text-shadow: 2px 2px 4px #421476;
`;
const Points = styled.div`
  font-size: 52px;
  font-family: "Alfa Slab One", cursive;
  color: #421476;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  border-radius: 16px;
  margin-left: 20px;
`;

